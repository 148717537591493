import React, { Component } from 'react';
import '../styles/mainpage.scss'
import '../styles/home.scss'

export class Home extends Component<any, any> {

  render() {

    return (
      <div id='home' className='background-image image-height'>
        <div className='container'>
          <h1 className='home-header home-text text-family'>
            Delivering Happiness at Door
          </h1>
        </div>
      </div>      
    );
  }
}