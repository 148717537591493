import React, { Component } from 'react';
import {Header} from './header';
import {Home} from './home';
import { Service } from './service';
import { Customers } from './customers';
import { About } from './about';
import { Work } from './work';
import { Contact } from './contact';

class MainPage extends Component {
  render() {
    return (
      <div>
        <Header />
        <Home />
        <Service />
        <Customers />
        <About />
        <Work />
        <Contact />
      </div>
    );
  }
}

export default MainPage;
