import React, { Component } from 'react';
import food from '../Images/Services/food.jpg';
import courier from '../Images/Services/courier.jpg'
import { ServiceFooter } from './service-content/service-footer';
import '../styles/service.scss';
import '../styles/mainpage.scss'

export class Service extends Component {
  
  render() {
    const imageView =(src: string, alt: string) => (
      <img className='service-image' src={src} alt={alt} />
    )
    const serviceName = (name:string) => (
      <h1 className='service-text text-family'>
        {name}
      </h1>
    );

    return (
      <div id='service' className='container'>
        <h1 className='header-text header-size text-family'>
          Services
        </h1>
       <div className='row'>
        <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
          {imageView(food, 'Food')}            
          {serviceName('Food')}
        </div>
        <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6'>
          {imageView(courier, 'Courier')}
          {serviceName('Courier')}
        </div>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
          <ServiceFooter />
        </div>
      </div>
      </div>
    );
  }
}