import React, { Component } from 'react';
import '../styles/about.scss';
import '../styles/service.scss';

export class About extends Component {
  render() {
    return (
        <div id='about' className='about-background-image'>
        <div className='container'>
          <h1 className='header-text header-size text-family'>
            About Us
          </h1>
          <h5 className='about-text text-family'>
            Classic Rider is a Hyper-local delivery company. We provide last mile fulfilment services to food, electronics and offline retail. We provide professional drivers of car and bike on fixed monthly charges.We provided services across the UAE🇦🇪 
          </h5> 
        </div>
      </div>   
    );
  }
}