import React, { Component } from 'react';
import logo from '../Images/Logo/logo.png';
import '../styles/header.scss';
import {  Navigation } from './navigation';

export class Header extends Component {
  render() {
    return (
      <div className='row bg-light'>
        <div className='col-xs-6 col-sm-6 col-md-4 col-lg-4'>
          <a href='/'>
            <img className='header-logo float-left' src={logo} alt='CRDS'/>
          </a>
        </div> 
        <div className='header-logo col-xs-6 col-sm-6 col-md-8 col-lg-8'>
          <Navigation />
        </div>
        
      </div>
    );
  }
}