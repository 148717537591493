import React, { Component } from 'react';
import { faHome, faBriefcase, faPhone } from '@fortawesome/free-solid-svg-icons';
import {faServicestack, faAdn} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/header.scss'; 

export class Navigation extends Component<any, any> {
  render() {
    return (
      <nav className='nav-bar-margin navbar navbar-expand navbar-light bg-light'>
          <ul className='navbar-nav text-family'>
            <li className='nav-item'>
              <a className='nav-link' href='#home'>
              <FontAwesomeIcon icon={faHome} /> Home</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='#service'>
              <FontAwesomeIcon icon={faServicestack} /> Services</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='#about'>
              <FontAwesomeIcon icon={faAdn} /> About</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='#work'>
              <FontAwesomeIcon icon={faBriefcase} /> Work</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='#contact'>
              <FontAwesomeIcon icon={faPhone} /> Contact</a>
            </li>
            </ul>
          
      </nav>
    );
  }
}