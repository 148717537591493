import React, { Component } from 'react';
import linkedin from '../Images/Linkedin/linkedin.png';
import { faEnvelope,faMobileAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/mainpage.scss';
import '../styles/contact.scss';

export class Contact extends Component {
  render() {
    return (
      <div id='contact' className='container h-50'>
        <h1 className='header-text header-size other-header-size text-family'>
          Contact Us
        </h1>
        <h6 className='contact-text text-family'>
          We provide professional drivers of car and bike on fixed monthly charges. We provided services across the UAE🇦🇪< br />
          We are having experienced back office support 24*7
        </h6>
        <div className='row'>
          <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
            <div className='text-family footer-text'>
              <h1>
                Email 
              </h1>
              <h6>
              <a href='mailto:Info@classicrds.com '>
                <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;&nbsp;Info@classicrds.com
              </a><br />
              <a href='mailto:Sales@classicrds.com '>
                <FontAwesomeIcon icon={faEnvelope} /> Sales@classicrds.com
              </a><br />
              </h6>
            </div>
          </div>
          <div className='text-family footer-text col-xs-12 col-sm-4 col-md-4 col-lg-4'>
            <h1>
                Phone 
            </h1>
            <h6>
              <a href='tel:+971-4-394-9416'>
                <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;&nbsp;+971-4-394-9416
              </a><br />
              <a href='tel:+971-55-348-5417'>
                <FontAwesomeIcon icon={faMobileAlt} /> +971-55-348-5417
              </a> <br />
              <a href='tel:+971-58-932-9118'>
                <FontAwesomeIcon icon={faMobileAlt} />&nbsp; +971-58-932-9118
              </a>
              </h6>
          </div>
          <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4 text-family footer-text'>
            <h1>
                Linkedin 
            </h1>
            <a href='https://www.linkedin.com/in/classic-rider-99300917b/'>
              <FontAwesomeIcon className='linkedin-image' icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}