import React, { Component } from 'react';
import '../styles/mainpage.scss';
import '../styles/work.scss';
import '../styles/service.scss';
import '../styles/customers.scss';

export class Work extends Component {
  render() {
    return (
        <div id='work' className='work-background-image'>
        <div className='container'>
          <h1 className='header-text header-size other-header-size text-family'>
            Work At Classic Rider
          </h1>
          <h5 className='work-text text-family'>
            We are looking for people who are simple, down-to-earth, hungry for knowledge, ambitious and willing to be a part of creating Next-Generation Enterprise in Middle-East.
            <br />
            Send us your resume at 
            <a href='mailto:zahid@classicrds.com'> zahid@classicrds.com </a> 
            and we will contact you for discussions
            </h5> 
        </div>
      </div>   
    );
  }
}