import React, { Component } from 'react';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/service.scss';

export class ServiceFooter extends Component {
  render() {
    return (
    <div className='service-box text-family'>
        <h6 className='sevice-footer'>
          We provide professional drivers of car and bike on fixed monthly charges.We provided services across the UAE.
          We are having experienced back office support 24*7
        </h6>
        <div className='service-contact'>
          <h3>
              Contact : 
          </h3>
          <a href='mailto:Sales@classicrds.com '>
          <FontAwesomeIcon icon={faEnvelope} /> Sales@classicrds.com
          </a> <br />
          <a href='mailto:Info@classicrds.com '>
          <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Info@classicrds.com
          </a>
        </div>
        
    </div>
    );
  }
}