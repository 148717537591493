import React, { Component } from 'react';
import '../styles/mainpage.scss';
import '../styles/service.scss';
import '../styles/customers.scss'

export class Customers extends Component {
  render() {
    const customer = (name: string) => (
        <button className='button text-family customer-button'>
          {name}
        </button>
    );
    return (
      <div id='customers' className='bg-light col-xs-12 col-sm-12 col-md-12 col-lg-12'>
        <div className='container'>
          <h1 className='header-text text-family header-size other-header-size'>
              Our Happy Customers
          </h1>
          <div className='customer-container'>
            {customer('Souq.com')}
            {customer('Noon')}
            {customer('K burger')}
            {customer('Kazoka restaurant')}
            {customer('Talabat')}
          </div>  
        </div>      
      </div>      
    );
  }
}